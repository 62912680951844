<template>
  <div>
    <el-row :gutter="20" class="examQuestionsManageTop">
      <el-col :span="8" v-for="(item, i) in subjectList" :key="item.name">
        <template v-if="item.subjectType <= 3 && item.subjectType >= 1">
          <div
            class="subjectBox"
            @click="clickBtn(i, item.subjectType)"
            :class="checkedNum == i + 1 ? 'active' : ''"
          >
            {{ item.subjectType | subjectFilter('考题') }}
            <span>
              {{ item.subjectTotal }}
              <i>道</i>
            </span>
            <div class="iconBig">
              <img
                :src="
                  require(`../../assets/examQuestions/${item.subjectType}.png`)
                "
                alt
              />

              <!-- <img :src="require(`../../assets/examQuestions/${item.subjectType}.png`)" alt /> -->
            </div>
          </div>
        </template>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 20px; display: flex">
      <!-- <el-upload
        style="display:inline-block;margin-right:10px"
        :limit="1"
        action="https://jsonplaceholder.typicode.com/posts/"
        :http-request="modeUpload"
        :show-file-list="false"
      >
        <el-button v-throttle  size="medium" type="primary" icon="el-icon-upload2">导入</el-button>
      </el-upload>-->
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="
          () => {
            this.$refs.uploadExcelCom.dialogBox = true
            this.uploadMsg = ''
          }
        "
        icon="el-icon-upload2"
        >导入</el-button
      >

      <el-upload
        :limit="1"
        action="https://jsonplaceholder.typicode.com/posts/"
        :http-request="uploadJsonFunc"
        :show-file-list="false"
        style="margin: 0 10px"
        ref="upload"
      >
        <el-button
          v-throttle
          size="medium"
          type="primary"
          icon="el-icon-upload2"
          >导入json文件</el-button
        >
      </el-upload>
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="templateBtn"
        icon="el-icon-download"
        >下载Excel模板</el-button
      >
      <router-link style="margin: 0 10px" to="/examQuestions/examQuestionsEdit">
        <el-button
          v-throttle
          size="medium"
          type="primary"
          icon="el-icon-circle-plus-outline"
          >添加</el-button
        >
      </router-link>
      <el-button
        v-throttle
        size="medium"
        type="danger"
        @click="deleteList"
        icon="el-icon-delete"
        >批量删除</el-button
      >
    </el-row>
    <!-- 表格 -->
    <el-row>
      <!-- :header-cell-style="{'text-align':'center'}"  -->
      <!-- stripe -->
      <el-table
        header-align="center"
        border
        :header-cell-style="{ background: '#DFE6EC' }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        v-loading="loadingTable"
      >
        <el-table-column
          align="center"
          type="selection"
          width="50"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="operationTestName"
          label="考题"
        ></el-table-column>
        <el-table-column align="center" label="学科" width="100">
          <template slot-scope="scope">{{
            scope.row.subjectType | subjectFilter
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="score"
          label="总分"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="paperless"
          label="电子试卷"
          width="130"
        >
          <template slot-scope="scope">{{
            scope.row.paperless ? '已开启' : '未开启'
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="times"
          label="使用次数"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="aiMarking"
          label="是否支持AI评分"
          width="130"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.aiMarking" style="color: #67c23a">支持</span>
            <span v-else style="color: #e6a23c">不支持</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="试卷" width="94">
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="primary"
              icon="el-icon-view"
              @click="testPaperCheck(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="答案" width="94">
          <template slot-scope="scope">
            <el-button v-throttle 
              size="mini"
              icon="el-icon-view"
              type="primary"
              @click="answerCheck(scope.$index, scope.row)"
            >查看</el-button>
          </template>
        </el-table-column>-->
        <el-table-column align="center" label="操作" width="330">
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="primary"
              @click="viewBtn(scope.$index, scope.row)"
              >查看</el-button
            >
            <el-button
              v-throttle
              size="mini"
              type="primary"
              @click="editBtn(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              v-throttle
              size="mini"
              type="primary"
              @click="derivedJsonBtn(scope.row.testQuestionId)"
              >导出json文件</el-button
            >
            <el-button
              v-throttle
              size="mini"
              type="danger"
              @click="deleteBtn(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tableDataPagination.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableDataPagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataPagination.total"
      ></el-pagination>
    </el-row>
    <!-- 答案图片预览弹框 -->
    <el-image-viewer
      v-if="previewImgShow"
      :on-close="() => (this.previewImgShow = false)"
      :url-list="[this.$imageAddress(previewImg)]"
    ></el-image-viewer>
    <!-- 答案，分数弹框 -->
    <el-dialog title="查看答案" :visible.sync="previewAnswerBox">
      <el-table
        :data="previewAnswerList"
        header-align="center"
        border
        :header-cell-style="{ background: '#DFE6EC' }"
      >
        <el-table-column align="center" label="序号">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column align="center" label="答案">
          <template slot-scope="scope">
            <div
              v-for="(val, i) in scope.row.answerList"
              :key="i"
              style="margin: 5px"
            >
              <span v-if="val.questionType == 3">
                <el-button
                  v-throttle
                  @click="
                    () => {
                      previewImgShow = true
                      previewImg = val.answer
                      previewAnswerBox = false
                    }
                  "
                  size="mini"
                  type="primary"
                  >查看画图</el-button
                >
              </span>
              <span v-else>{{ val.answer }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="score"
          label="分数"
        ></el-table-column>
      </el-table>
    </el-dialog>
    <upload-excel-com
      @uploadFunc="modeUpload"
      ref="uploadExcelCom"
      :uploadMsg="uploadMsg"
      :loading="uploadLoading"
    ></upload-excel-com>
  </div>
</template>

<script>
import {
  downloadQuestion,
  importExcelQuestion,
  occupy,
} from '@/api/importExport.js'
import * as questions from '@/api/examQuestions/examQuestions.js'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'examQuestionsManage',
  components: { ElImageViewer },
  data() {
    return {
      // url: 'http://192.168.6.226:9000',
      // 答案
      previewAnswerList: [],
      previewAnswerBox: false,
      // 试卷
      previewImgShow: false,
      previewImg: '',
      subjectList: [],
      checkedNum: 0,
      tableData: [],
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 10,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 0,
        subjectType: undefined,
      },
      // 多选框选中
      checkedList: [],
      //
      mode: {},
      uploadMsg: '',
      uploadLoading: false,

      loadingTable: false,
    }
  },
  mounted() {
    this.topRefresh()
    this.inquire(this.tableDataPagination)
  },
  methods: {
    // 切换
    clickBtn(i, subjectType) {
      if (this.checkedNum == i + 1) {
        this.checkedNum = 0
        this.tableDataPagination.subjectType = undefined
      } else {
        this.checkedNum = i + 1
        this.tableDataPagination.subjectType = subjectType
      }
      this.tableDataPagination.pageIndex = 1
      this.inquire(this.tableDataPagination)
    },
    // 导入
    modeUpload(fd) {
      this.uploadLoading = true
      // console.log('item', item.file)
      // let fd = new FormData()
      // fd.append('file', item.file)
      // console.log('fd', fd)
      importExcelQuestion(fd)
        .then((res) => {
          this.uploadMsg = ''
          console.log('res', res)
          if (res.success) {
            this.$message({
              showClose: true,
              message: '导入成功',
              type: 'success',
              duration: 10000,
            })
            this.topRefresh()
            this.inquire(this.tableDataPagination)
            this.$refs.uploadExcelCom.handleClose()
          } else {
            this.uploadMsg = res.msg
            this.$refs.uploadExcelCom.clearFile()
            // this.$message.warning(res.msg)
          }
          this.uploadLoading = false
        })
        .catch((err) => {
          this.uploadLoading = false
        })
    },
    // JSON下载
    derivedJsonBtn(val) {
      questions.derivedJson(val).then((res) => {
        console.log('下载excel模板', res)
        if (res.success) {
          this.$message.success('下载成功')
          // window.open(this.$imageAddress(res.data))
          fetch(this.$imageAddress(res.data), {
            headers: {
              RPAuthorization: localStorage.getItem('RPAuthorization'),
            },
          })
            .then((response) => response.json())
            .then((data) => {
              console.log('内容', data)
              const name = data.testQuestionParam.operationTestName
              const blob = new Blob([JSON.stringify(data)]) // 将字节流(字符流)转换为 blob 对象

              let url = window.URL.createObjectURL(blob)
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.download = `${name}.json`
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link) // 下载完成移除元素
              window.URL.revokeObjectURL(url) // 释放掉blob对象
            })
        } else {
          this.$message.error('下载失败:', res.msg)
        }
      })
    },
    // 批量删除
    async deleteList() {
      if (this.checkedList.length != 0) {
        let Jump = []
        Jump = await this.occupyFunc(this.checkedList)
        console.log('Jump', Jump)
        console.log('his.checkedList', this.checkedList)
        let list = []
        list = this.checkedList.filter((items) => {
          if (!Jump.includes(items)) return items
        })
        console.log('list', list)
        if (list.length == 0)
          return this.$message.warning('所选考题占用无法删除')
        this.$confirm('是否批量删除选中考题?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            questions.questionsDelete(list).then((res) => {
              if (res.data) {
                this.$message.success('批量删除成功')
                this.inquire(this.tableDataPagination)
                this.topRefresh()
              } else {
                this.$message.warning('删除失败')
              }
            })
          })
          .catch(() => {
            this.$message.info('已取消删除')
          })
      } else {
        this.$message.warning('请选择要删除的考题')
      }
    },
    // 导入json文件
    uploadJsonFunc(item) {
      console.log('上传了')
      let fd = new FormData()
      // fd.append('code', 'default')
      fd.append('file', item.file)
      questions.importJson(fd).then((res) => {
        console.log('导入json文件', res)
        if (res.success) {
          this.$message.success('导入成功')
          this.topRefresh()
          this.inquire(this.tableDataPagination)
        } else {
          this.$message.error(res.msg)
        }
        this.$refs.upload.clearFiles() // 清空历史记录
      })
    },
    // 试卷查看
    testPaperCheck(i, val) {
      // getPdfById

      console.log('val.paperPdf', val.paperPdf)
      if (!val.paperPdf) return this.$message.warning('没有电子试卷')
      this.previewImg = val.paperPdf
      this.previewImgShow = true
    },
    // 答案查看
    answerCheck(i, val) {
      console.log('val', val)
      questions.getAnswersById(val.testQuestionId).then((res) => {
        console.log('查看答案', res)
        if (res.success) {
          this.previewAnswerList = res.data
          this.previewAnswerBox = true
        }
      })
    },
    // 查看
    async viewBtn(i, val) {
      this.$router.push({
        path: '/examQuestions/examQuestionsView?Id=' + val.testQuestionId,
      })
    },
    // 编辑
    async editBtn(i, val) {
      // let Jump = []
      // Jump = await this.occupyFunc([val.testQuestionId])
      // if (!!Jump.length) return this.$message.info('考题被占用无法编辑')
      if (!!val.times) return this.$message.info('考题被占用无法编辑')
      this.$router.push({
        path: '/examQuestions/examQuestionsEdit?Id=' + val.testQuestionId,
      })
    },
    // 下载Excel模板
    templateBtn() {
      downloadQuestion().then((res) => {
        console.log('导出json文件', res)
        if (res.success) {
          this.$message.success('下载成功')
          window.open(this.$apiAddress(res.data))
        } else {
          this.$message.error('下载失败:', res.msg)
        }
      })
    },
    // 删除
    async deleteBtn(i, val) {
      let Jump = []
      Jump = await this.occupyFunc([val.testQuestionId])
      console.log('Jump', Jump)
      if (!!Jump.length) return this.$message.info('考题被占用无法删除')
      this.$confirm('是否删除该考题?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          questions.questionsDelete(val.testQuestionId).then((res) => {
            if (res.data) {
              this.$message.success('删除成功')
              this.inquire(this.tableDataPagination)
              this.topRefresh()
            } else {
              this.$message.warning('删除失败')
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    // 左侧选中
    handleSelectionChange(val) {
      this.checkedList = []
      val.forEach((item) => {
        this.checkedList.push(item.testQuestionId)
      })
    },
    // console.log(`每页 ${val} 条`);
    handleSizeChange(val) {
      this.tableDataPagination.pageSize = val
      this.inquire(this.tableDataPagination)
    },
    // console.log(`当前页: ${val}`);
    handleCurrentChange(val) {
      this.tableDataPagination.pageIndex = val
      this.inquire(this.tableDataPagination)
    },
    // 顶部刷新
    topRefresh() {
      // 请求顶部信息
      questions.getSubjectTotal().then((res) => {
        if (res.success) {
          this.subjectList = JSON.parse(JSON.stringify(res.data))
        }
      })
    },
    // 分页查询
    inquire(list) {
      const data = {
        page: this.tableDataPagination.pageIndex,
        rows: this.tableDataPagination.pageSize,
        subjectType: this.tableDataPagination.subjectType,
      }
      this.loadingTable = true
      questions
        .selectByPages(data)
        .then((res) => {
          this.loadingTable = false
          if (res.success) {
            this.tableData = res.data
            this.tableDataPagination.total = res.total
            this.tableDataPagination.pageTotal = res.pageTotal
            console.log('tableData', this.tableData)
          }
        })
        .catch((err) => {
          console.log('err', err)
          this.loadingTable = false
        })
    },
    occupyFunc(val) {
      return new Promise((resolve, reject) => {
        occupy(val).then((res) => {
          if (res.success) {
            resolve(res.data)
          }
        })
      })
    },
  },
  filters: {
    subjectFilter(key, font = '') {
      switch (key) {
        case 1:
          return `物理${font}`
        case 2:
          return `生物${font}`
        case 3:
          return `化学${font}`
        default:
          return `未知学科${font}`
      }
    },
  },
}
</script>

<style lang="scss">
.examQuestionsManageTop {
  border-bottom: 1px solid #c8dbec;
  padding-bottom: 20px;
  margin-bottom: 20px;

  .el-col:nth-child(1) .iconBig {
    // background-image: linear-gradient(to bottom, #2885da, #6eb7fc) !important;
  }

  .el-col:nth-child(2) .iconBig {
    // background-image: linear-gradient(to bottom, #f18f11, #febe6e) !important;
  }

  .el-col:nth-child(3) .iconBig {
    // background-image: linear-gradient(to bottom, #11ccde, #5ad5e0) !important;
  }
}

.subjectBox {
  width: 100%;
  box-sizing: border-box;
  background: rgb(248, 248, 248);
  border: 2px solid #409eff00;
  cursor: pointer;
  height: 70px;
  color: rgb(102, 102, 102);
  font-size: 14px;
  padding: 6px;
  padding-left: 66px;
  position: relative;

  .iconBig {
    // background-image: linear-gradient(to bottom, #2885da, #6eb7fc);
    width: 52px;
    height: 52px;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    // i {
    //   font-size: 30px;
    //   color: white;
    // }
    img {
      width: 100%;
      height: 100%;
    }
  }

  span {
    display: block;
    color: #409eff;
    font-size: 26px;

    i {
      font-style: normal;
      font-size: 14px;
      color: rgb(102, 102, 102);
    }
  }

  &:hover {
    background: rgb(213, 213, 213);
  }

  &.active {
    border: 2px solid #409eff;
  }
}
</style>
<style scoped>
::v-deep .el-image-viewer {
  z-index: 9999 !important;
}
</style>
